import React, { Component } from "react";
import "./Channel.css";

class Channel extends Component {
  render() {
    return (
      <div className={"channel"}>
        <a href={this.props.link}>
          <img alt="Publicidade Legal" src={this.props.image} />
        </a>
      </div>
    );
  }
}

export default Channel;
