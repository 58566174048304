import React, { Component } from "react";
import queryString from "query-string";
import "./Error.css";

class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };
  }

  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({
      message: values.message || this.getMessageFromStatus(values.status)
    });

    setTimeout(() => (document.getElementById("root").style.display = ""), 50);
  }

  getMessageFromStatus(status) {
    switch (parseInt(status)) {
      case 404:
        return "Página não encontrada";
      default:
        return status ? `Ocorreu um erro (${status}).` : "Ocorreu um erro.";
    }
  }

  render() {
    return (
      <div className="error">
        <h1>Erro</h1>
        <h2>{this.state.message}</h2>
      </div>
    );
  }
}

export default Error;
