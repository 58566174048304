import React, { Component } from "react";
import "./Conclusion.css";

class Conclusion extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    setTimeout(() => (document.getElementById("root").style.display = ""), 50);
  }

  render() {
    return (
      <div>
        <div className="conclusion-msg">Obrigado por nos informar seus dados, em breve entraremos em contato!</div>

        <div className="whatsapp">
          <button
            className="whatsapp-button"
            onClick={() =>
              window.open(
                "http://api.whatsapp.com/send?1=pt_BR&phone=5548991885651"
              )
            }
          >
            <img
              src="https://s3-sa-east-1.amazonaws.com/nsc-classificados/dev/uploads/2019/11/22092742/whatsapp-48.png"
              alt="whatsapp icon"
              width="16"
              style={{ position: "relative", top: "3px", marginRight: "3px", width: "20px" }}
            />
            {"  "}
            <span id="cta-button-desk">
              Ou se preferir, fale agora mesmo com um consultor
            </span><span id="cta-button-mobile">PUBLIQUE CONOSCO</span>
          </button>
        </div>
      </div>
    );
  }
}

export default Conclusion;
